import { UseSeoMetaInput } from '@vueuse/head'

type Opts = {
  title: string
  description: string
  type?: 'article' | 'website'
}

export function useCustomServerMeta({ title, description, type }: Opts) {
  const input: UseSeoMetaInput = {
    title,
    ogTitle: title,
    description,
    ogDescription: description,
    ogType: type || 'website',
    twitterTitle: title,
    twitterDescription: description,
  }
  useSeoMeta(input)
}
